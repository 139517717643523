
<style scoped>
:deep(.ant-menu-item-icon) {
  font-size: 16px;
}

.badge {
  position: absolute;
  top: 12px;
  right: 0;
  display: inline-block;
  border-radius: 8px;
  padding: 0 4px;
  height: 16px;
  text-align: center;
  color: white;
  background-color: red;
  line-height: 16px;
}
</style>

<script lang="tsx">
import { defineComponent, ref, h, computed } from 'vue'
import { useRouter, RouteRecordRaw, RouteLocationNormalizedLoaded, RouteMeta } from 'vue-router'
import BasicIcon from '@/components/icon/basic-icon.vue'
import * as antIcons from '@ant-design/icons-vue'
import { camelCase, cloneDeep, upperFirst } from 'lodash-es'
import { listenerRouteChange } from '@/mitt/route-listener'
import { useAppStore, useSystemStore } from '@/store'
import { useUserStore } from '../../../store/modules/user'
import { depthToArr } from '@/utils/index'
import { isFunction } from '@/utils/is'

export default defineComponent({
  emit: ['collapse'],
  components: { BasicIcon },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const menus = useUserStore().menus
    const systemStore = useSystemStore()


    const menuTree = computed(() => {
      // 获得菜单项配置
      const copyRouter = cloneDeep(menus)
      function travel(_routes: RouteRecordRaw[]) {
        if (!_routes) {
          return null
        }
        const collector: any = _routes.map(element => {
          if (!element.children) {
            return element
          }

          const children = element.children.filter(x => x.meta?.hideInMenu !== true)

          element.children = children

          const subItem = travel(element.children)
          if (subItem.length && !element.meta?.hideInMenu) {
            element.children = subItem
            return element
          }

          if (!element.meta?.hideInMenu) {
            return element
          }

          return null
        })
        let arr = collector.filter(Boolean)

        if (!systemStore.isMobile && systemStore.layout.splitMenu) {
          let find = arr.find(item => item.path === systemStore.selectedTopMenu && item.children)
          if (find) {
            arr = find.children
          }
        }
        return arr
      }
      return travel(copyRouter)
    })
    const goto = routeConfig => {
      if (routeConfig.meta?.component?.indexOf('http') !== -1) {
        window?.open(routeConfig.meta?.component)
        return
      }
      selectedKey.value = [routeConfig.name as string]
      router.push({ name: routeConfig.name })
    }
    // 监听路由变化，设置当前选中的菜单 key
    const selectedKey = ref<string[]>([route.name as string]) // 当前选中的菜单项名
    const openKeys = ref<any[]>([])

    const openBySubMenuFn = () => {
      let find = menus.find(item => item.path === systemStore.selectedTopMenu && item.children)
      if (find) {
        openKeys.value = depthToArr(find.children ?? []).map(item => item.name)
      }
    }

    const openMenuFn = (route: RouteLocationNormalizedLoaded) => {
      const findOpenMenuKeys = (menus: RouteRecordRaw[], targetName: string, path: string[] = []): string[] => {
        for (const menu of menus) {
          if (menu.name === targetName) {
            return [...path, menu.name]
          }
          if (menu.children) {
            const childPath = findOpenMenuKeys(menu.children, targetName, [...path, String(menu.name ?? '')])
            if (childPath.length) {
              return childPath
            }
          }
        }
        return []
      }

      const menuPath = findOpenMenuKeys(menus, route.name as string)

      if (menuPath.length) {
        openKeys.value = menuPath.slice(0, -1) // 祖先节点作为 openKeys
      }
    }

    const openMenu = (_route:RouteLocationNormalizedLoaded) => {
      // 判断是否是splitMenu模式,自动展开二级所有菜单
      if (!systemStore.isMobile && systemStore.layout.splitMenu) {
        openBySubMenuFn()
      } else {
        openMenuFn(_route)
      }
    }

    listenerRouteChange(newRoute => {
      if (!newRoute.meta.hideInMenu && newRoute.name) {
        selectedKey.value = [newRoute.name as string]
      }
      if (!systemStore.layout.sidebar.collapsed) {
        openMenu(newRoute)
      }

    }, true)

    watch(() => systemStore.selectedTopMenu, () => {
      if (!systemStore.layout.sidebar.collapsed) {
        openMenu(route)
      }
    })

    const renderSubMenu = () => {
      function renderTitle(routeConfig) {
        let title = routeConfig.meta?.title || ''
        let { notifications } = useAppStore()
        let { showBadge } = routeConfig.meta as RouteMeta
        if (showBadge) {
          let count = (isFunction(showBadge) ? showBadge(notifications) : notifications[showBadge]) ?? 0
          return (<div class="flex justify-between relative">
            <span>{title}</span>
            {(count != 0 && !isNaN(count)) && <span class="badge">
              {count > 99 ? '99+' : count}
            </span>}
          </div>)
        }
        return title
      }
      function travel(routeConfigList: RouteRecordRaw[], nodes = []) {
        if (routeConfigList) {
          routeConfigList.forEach(routeConfig => {
            const UpperIcon = upperFirst(camelCase(routeConfig.meta?.icon))

            // 判断是否有子菜单
            const r = routeConfig.children && routeConfig.children.length >= 1
              ? (
                <a-sub-menu
                  key={routeConfig.name}
                  v-slots={{
                    icon: () => ((routeConfig.meta?.icon)
                      ? h(BasicIcon, { name: routeConfig.meta.icon })
                      : h(antIcons[UpperIcon!])),
                    title: () => renderTitle(routeConfig),
                  }}
                >
                  {travel(routeConfig.children)}
                </a-sub-menu>
              )
              : (
                <a-menu-item
                  key={routeConfig.name}
                  onClick={() => {
                    goto(routeConfig)
                  }}
                  v-slots={{
                    icon: () => ((routeConfig.meta?.icon)
                      ? h(BasicIcon, { name: routeConfig.meta.icon })
                      : h(antIcons[UpperIcon!])),
                  }}
                >
                  {renderTitle(routeConfig)}
                </a-menu-item>
              )
            nodes.push(r as never)
          })
        }
        return nodes
      }
      return travel(menuTree.value)
    }

    return () => (
      <div style="height: 100%">
        <a-menu
          selected-keys={selectedKey.value}
          open-keys={openKeys.value}
          theme={systemStore.layout.sidebar.theme}
          style="height: 100%; overflow-y: auto; overflow-x:hidden;"
          mode="inline"
        >
          {renderSubMenu()}
        </a-menu>
      </div>
    )
  },
})
</script>