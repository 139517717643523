import { request } from '@/utils/request'


/** 资金管理 */
const fundManagementApi = {


  /** 余额明细 */
  balanceDetail: params => request.get('/Payment/PaymentLogs/index', params),
  // 客户单位资金流水列表
  getProjectCompanyFinanceLogsList: params => request.get('ProjectCompany/ProjectCompanyFinanceLogs/index', params),
  updateAdjustBalance: params => request.post('/projectCompany/projectCompanys/adjustBalance', params),


  /** 发薪账户 */
  getWageAccount: () => request.get('Payment/PaymentAccounts/index'),
}

/** 发新单接口 */
const wageApi = {
  payServiceCharege: params => request.post('Salary/StaffUserSalaryPays/payOrderPassword', params),
  // 新增发薪账单
  storeWage: params => request.post('Salary/StaffUserSalaryBills/store', params),

  getInvoiceBaseInfosDetail: id => request.get(`invoice/invoiceBaseInfos/detail/${id}`),

  /** 获取发薪列表 */
  getWageRecordList: params => request.get('Salary/StaffUserSalaryPays/index', params),

  // 发薪记录统计
  getStatisticalAmount: (params?:any) => request.get('Salary/StaffUserSalaryPays/statisticalAmount', params),

  /** 发薪详情 */
  getWageRecordDetail: id => request.get('Salary/StaffUserSalaryPays/show', { id }),

  /** 发薪导入检测 */
  wageImportChecked: params => request.post('Salary/StaffUserSalaryPays/importStaffUserCheck', params),


  /** 补发检测 */
  reissueChecked: params => request.post('Salary/StaffUserSalaryPays/importReissueStaffUserCheck', params),

  /** 发薪成员列表 */
  getWageRecordDetailList: params => request.get('Salary/StaffUserSalaryPayDetails/index', params),

  /** 支付账户 */
  getPayAccount: () => request.get('Payment/PaymentAccounts/selector'),

  /** 导入发薪 */
  importWage: params => request.post('Salary/StaffUserSalaryPays/store', params),

  /** 发薪明细列表 */
  getWageDetailList: params => request.get('Salary/StaffUserSalaryPayDetails/index', params),

  // 获取发薪成员列表
  getBillDetailsList: params => request.get('Salary/StaffUserSalaryBillDetails/index', params),

  // 导入完善员工发薪
  importBillStaffUser: params => request.post('Salary/StaffUserSalaryBillDetails/importStaffUser', params),
  // 获取发薪账单列表
  getWageBillList: params => request.get('Salary/StaffUserSalaryBills/index', params),
  // 发薪账单导入检测
  wageBillImportChecked: params => request.post('Salary/StaffUserSalaryBills/importStaffUserCheck', params),
  // 获取发薪账单详情
  getBillWageRecordDetail: params => request.get('Salary/StaffUserSalaryBills/show', params),

  /** 发薪账户 */
  getWageAccount: params => request.get('Payment/PaymentAccounts/index', params),

  /** 新增账户 */
  storeAccount: params => request.post('/Payment/PaymentAccounts/store', params),

  /** 冻结账户 */
  frozenAccount: id => request.get('/Payment/PaymentAccounts/frozenAccount', { id }),

  /** 激活账户，也是解冻账户 */
  activeAccount: id => request.get('/Payment/PaymentAccounts/activeAccount', { id }),

}

// 服务费管理接口
const serviceChargeApi = {
  getServiceChargeList: params => request.get('/Salary/ServiceChargeOrders/index', params),
  // 支付
  payServiceCharege: params => request.post('/Salary/ServiceChargeOrders/payOrder', params),
  // 获取详情
  getDetialServiceCharge: id => request.get('/Salary/ServiceChargeOrders/show', { id }),

}

// 服务费账单页面的
const invoice2Api = {
  // 申请开票
  storeInvoice: params => request.post('/invoice/invoices/store', params),
  // 开票记录
  invoiceList: params => request.get('/invoice/invoices/index', params),
  // 开票
  makeInvoice: params => request.post('/invoice/invoices/makeInvoice', params),
  // 开票详情
  invoiceDetail: id => request.get('/invoice/invoices/show', { id }),
  // 快递公司列表
  expressCompanyList: () => request.get('/invoice/invoices/expressCompany', {}),
  // 确认收到
  confirmReceiveInvoice: params => request.post('/invoice/invoices/receiveInvoice', params),
}

/** 发票模块 */
const invoiceApi = {
  // 获取发票信息列表
  getinvoiceBaseInfosList: params => request.get('/invoice/invoiceBaseInfos/index', params),
  getInvoiceCompany: params => request.get('/invoice/invoiceBaseInfos/getCompanyInfo', params),
  addinvoiceBaseInfos: params => request.post('/invoice/invoiceBaseInfos/store', params),
  updateinvoiceBaseInfos: params => request.put('/invoice/invoiceBaseInfos/store', params),


  //

  /** 列表 */
  getInvoiceList: params => request.get('/invoice/invoices/index', params),

  // 详情
  getInvoiceDetail: params => request.get('/invoice/invoices/show', params),

  // 申请开票，新增/修改
  newAndUpdateInvoice: params => request.post('/invoice/invoices/store', params),

  // 获取可开票的发薪记录
  getCanInvoiceSalaryPays: params => request.get('/Salary/StaffUserSalaryPays/getCanInvoiceSalaryPays', params)
}

/** 寄送地址管理 */
const sendAddrManagementApi = {
  // 列表
  getAddrList: params => request.get('/invoice/invoiceSendAddresses/index', params),
  // 新增/更新
  newAndUpdate: params => request.post('/invoice/invoiceSendAddresses/store', params),
  // 删除
  delAddr: params => request.post('/invoice/invoiceSendAddresses/delete', params),

  // 下拉框
  invoiceSendAddrSelect: params => request.get('/invoice/invoiceSendAddresses/selector', params),

  // 获取默认地址
  defaultInvoiceSendAddr: () => request.get('/invoice/invoiceSendAddresses/defaultInvoiceSendAddress')
}

export { wageApi, invoiceApi, sendAddrManagementApi, fundManagementApi, serviceChargeApi, invoice2Api }