<template>
  <custom-api-select
    :api="dictionaryApi.getDictionaryList"
    :api-params="{ pcode: 'invoice_content',...apiParams }"
    :immediate="true"
    :value-field="valueField"
    :label-field="labelField"
  />
</template>
<script lang="ts" setup>
import { dictionaryApi } from '@/api/system'
import { invoiceContentSelectProps } from './props'
const props = defineProps(invoiceContentSelectProps)
const { apiParams, valueField, labelField } = props
</script>
