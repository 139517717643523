
// ant-design 库组件
import Input, { InputProps } from 'ant-design-vue/es/input'
import InputNumber, { InputNumberProps } from 'ant-design-vue/es/input-number'
import TimePicker, { TimePickerProps } from 'ant-design-vue/es/time-picker'
// import Switch,{ SwitchProps } from 'ant-design-vue/es/switch'
import Radio, { RadioProps } from 'ant-design-vue/es/radio'
import Select, { SelectProps } from 'ant-design-vue/es/select'
import Cascader, { CascaderProps } from 'ant-design-vue/es/cascader'
import Checkbox, { CheckboxProps } from 'ant-design-vue/es/checkbox'
import { DatePickerProps, MonthPickerProps, WeekPickerProps, RangePickerProps } from 'ant-design-vue/es/date-picker'
import { TreeSelectProps } from 'ant-design-vue/es/tree-select'
import Tree, { TreeProps } from 'ant-design-vue/es/tree'
// 若从 es 模块中引用 datePicker，将导致 datePicker 样式丢失，无法正常显示，故在此通过 ant-design-vue 中导入
import { DatePicker, TreeSelect } from 'ant-design-vue'


// 业务无关自定义通用组件
import InputMobile, { InputMobileProps } from './common-components/input-mobile'
import InputMoney, { InputMoneyProps } from './common-components/input-money'
import RegionSelect, { RegionSelectProps } from './common-components/region-select'
import ErpRegionSelect, { ErpRegionSelectProps } from './common-components/erp-region-select'
import PureDisplay, { PureDisplayProps } from './common-components/pure-display'
import { CustomApiSelect, CustomApiSelectJob, CustomApiSelectProps } from './common-components/custom-api-select'
import InputTextArea, { InputTextAreaProps } from './common-components/input-textarea'
import InputTextareaUpload, { InputTextareaUploadProps } from './common-components/input_textarea_upload'
import InputMoneyTip, { InputMoneyTipProps } from './common-components/input-money-tip'
import DynamicForm, { DynamicFormProps } from '@/components/form/components/common-components/dynamic-form'
import Switch, { SwitchProp } from '@/components/form/components/common-components/custom-switch'
import InputRange, { InputRangeProps } from './common-components/input-range'
import ExportTableSelect, { ExportTableSelectProps } from '@/components/form/components/common-components/upload-table-select'
import InputSuffix, { InputSuffixProps } from './common-components/input-suffix'
// 业务组件
import Upload, { UploadProps } from './business-components/upload'

import socialUpload from './business-components/socialUpload'
import InputCaptcha, { InputCaptchaProps } from './business-components/input-captcha'
import InputButton, { InputButtonProps } from './business-components/input-button'

import ReferenceSelect, { ReferenceSelectProps } from './business-components/reference-select'
import CommonApiSelect, { CommonApiSelectProps } from './business-components/common-api-select'
import { CompanyApiSelect, CompanyApiSelectTypeOne, CompanyApiSelectTypeTwo, CompanyApiSelectProps } from './business-components/company-api-select'

import ClientApiSelect, { ClientApiSelectProps } from './business-components/client-api-select'
import InvoiceContentSelect, { InvoiceContentSelectProps } from './business-components/invoice-content-select'
import InvoiceExpressCompanySelect, { InvoiceExpressCompanySelectProps } from './business-components/invoice-express-company-select'
import SendAddrSelect, { SendAddrSelectProps } from './business-components/send-addr-select'
import AdminUserApiSelect, { AdminUserApiSelectProps } from './business-components/admin-user-api-select'
import ProjectApiSelect, { ProjectApiSelectProps } from './business-components/project-api-select'
import PositionTypeApiSelect, { PositionTypeApiSelectProps } from './business-components/position-type-api-select'
import TreeApiSelect, { TreeApiSelectProps } from './business-components/tree-api-select'
import SocialCascader, { SocialCascaderProps } from './business-components/social-plan-cascader'
// import SocialTableForm, { SocialTableFormProps } from './business-components/social-table-form'
import ExcelImport, { ExcelImportProps } from './business-components/excel-import'
import ExcelImport2, { ExcelImport2Props } from './business-components/excel-import2'
import JobResumeImport, { JobResumeImportProps } from './business-components/job-resume-import'
import realnamecheckExcelImport, { RealnamecheckExcelImportProps } from './business-components/realnamecheck-excel-import'
import WangEditor, { WangEditorProps } from './business-components/wang-editor'
import DynamicCascadeSelect, { DynamicCascadeSelectProps } from './business-components/dynamic-cascade-select'
import Calendar, { CalendarProps } from './business-components/calendar'
import AttendanceShiftTable, { AttendanceShiftProps } from './business-components/attendance-shift-table'
import DeptTreeSelect, { DeptTreeSelectProps } from './business-components/dept-tree-select'
import ApiTreeSelect, { ApiTreeSelectProps } from './business-components/api-tree-select'
import InsuranceRegionCascade, { InsuranceRegionSelectProps } from './business-components/RegionCascader'
import VacationSelect, { VacationSelectProps } from './business-components/vacation-select'
import UserModalSelect from './business-components/user-modal-select/user-modal-select'
import EmployerPostInputMany from './business-components/EmployerPostInputMany/EmployerPostInputMany.vue'
import CommercialInsuranceIndustryPicker from './business-components/industry-tree-select/CommercialInsuranceIndustryPicker.vue'

export const componentTypeMap = {
  Input,
  InputPassword: Input.Password,
  InputSearch: Input.Search,
  InputNumber,
  Cascader,
  Select,
  DatePicker,
  MonthPicker: DatePicker.MonthPicker,
  WeekPicker: DatePicker.WeekPicker,
  DateRangePicker: DatePicker.RangePicker,
  TimePicker,
  Switch,
  Radio,
  RadioGroup: Radio.Group,
  Checkbox,
  CheckboxGroup: Checkbox.Group,
  TreeSelect,
  Tree,
  InputRange,


  // 自定义通用组件
  InputMobile,
  InputMoney,
  RegionSelect,
  ErpRegionSelect,
  PureDisplay,
  CustomApiSelect,
  CustomApiSelectJob,
  InputTextArea,
  InputTextareaUpload,
  InputMoneyTip,
  DynamicForm,
  ExportTableSelect,
  InputSuffix,
  // CustomSwitch,
  // 业务组件，调用特定业务接口
  Upload,
  socialUpload,
  InputCaptcha,
  InputButton,
  ReferenceSelect,
  CommonApiSelect,
  CompanyApiSelect,
  CompanyApiSelectTypeOne,
  CompanyApiSelectTypeTwo,
  ClientApiSelect,
  InvoiceContentSelect,
  InvoiceExpressCompanySelect,
  SendAddrSelect,
  AdminUserApiSelect,
  ProjectApiSelect,
  PositionTypeApiSelect,
  TreeApiSelect,
  SocialCascader,
  // SocialTableForm,
  ExcelImport,
  ExcelImport2,
  JobResumeImport,
  realnamecheckExcelImport,
  WangEditor,
  DynamicCascadeSelect,
  Calendar,
  AttendanceShiftTable,
  DeptTreeSelect,
  ApiTreeSelect,
  InsuranceRegionCascade,
  VacationSelect,
  UserModalSelect,
  EmployerPostInputMany,
  CommercialInsuranceIndustryPicker
}

export type ComponentType = keyof typeof componentTypeMap |

  /** 页面传入的自定义组件 */
  'Custom'


export type ComponentProp<T extends ComponentType> = {
  'Input': InputProps,
  'InputPassword': InputProps,
  'InputSearch': InputProps,
  'InputNumber': InputNumberProps,
  'DatePicker': DatePickerProps,
  'MonthPicker': MonthPickerProps,
  'WeekPicker': WeekPickerProps,
  'DateRangePicker': RangePickerProps,
  'TimePicker': TimePickerProps,
  'Switch': SwitchProp,
  'Radio': RadioProps,
  'Select': SelectProps,
  'Cascader': CascaderProps,
  'Checkbox': CheckboxProps,
  'TreeSelect': TreeSelectProps,
  'Tree': TreeProps,
  'InputRange':InputRangeProps,

  // 自定义通用组件
  'CustomApiSelect': CustomApiSelectProps,
  'CustomApiSelectJob': CustomApiSelectProps,
  'InputMobile': InputMobileProps,
  // 'CustomSwitch':CustomSwitchProps,
  'InputMoney': InputMoneyProps,
  'RegionSelect': RegionSelectProps,
  'ErpRegionSelect':ErpRegionSelectProps,
  'InputTextArea': InputTextAreaProps,
  'InputTextareaUpload':InputTextareaUploadProps,
  'PureDisplay': PureDisplayProps,
  'InputMoneyTip': InputMoneyTipProps,
  'DynamicForm': DynamicFormProps,
  'ExportTableSelect': ExportTableSelectProps,
  'InputSuffix': InputSuffixProps,
  // 业务组件，调用特定业务接口
  'Upload': UploadProps,
  'socialUpload':'socialUploadProps',
  'InputCaptcha': InputCaptchaProps,
  'InputButton': InputButtonProps,
  'ReferenceSelect': ReferenceSelectProps,
  'CommonApiSelect': CommonApiSelectProps,
  'CompanyApiSelect': CompanyApiSelectProps,
  'CompanyApiSelectTypeOne': CompanyApiSelectProps,
  'CompanyApiSelectTypeTwo':CompanyApiSelectProps,
  'ClientApiSelect': ClientApiSelectProps,
  'InvoiceContentSelect': InvoiceContentSelectProps,
  'InvoiceExpressCompanySelect': InvoiceExpressCompanySelectProps,
  'SendAddrSelect': SendAddrSelectProps,
  'AdminUserApiSelect': AdminUserApiSelectProps,
  'ProjectApiSelect': ProjectApiSelectProps,
  'PositionTypeApiSelect': PositionTypeApiSelectProps,
  'TreeApiSelect': TreeApiSelectProps,
  'SocialCascader': SocialCascaderProps,
  // 'SocialTableForm': SocialTableFormProps,
  'DynamicCascadeSelect': DynamicCascadeSelectProps
  'ExcelImport': ExcelImportProps,
  'ExcelImport2': ExcelImport2Props,
  'ManyImport': JobResumeImportProps,
  'realnamecheckExcelImport': RealnamecheckExcelImportProps
  ,
  'WangEditor': WangEditorProps,
  'AttendanceShiftTable': AttendanceShiftProps,
  'DeptTreeSelect': DeptTreeSelectProps,
  'ApiTreeSelect': ApiTreeSelectProps,
  'InsuranceRegionCascade':InsuranceRegionSelectProps,
  'VacationSelect': VacationSelectProps,
  'UserModalSelect': any,
  'EmployerPostInputMany': any,
  'CommercialInsuranceIndustryPicker':any,
  Calendar: CalendarProps
  [K: string]: any,
}[T]