import { authApi } from '@/api/auth'
import { useAppStore, useSystemStore, useUserStore } from '@/store'
import { ProjectCode, reLoginTips } from '@/utils/request'
import { getItem, STORAGE_KEY_TOKEN } from '@/utils/storage'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { Router } from 'vue-router'

NProgress.configure({ showSpinner: false })

// 设置路由守卫，进入每个路由前的操作
export default function setupPermissionGuard(router: Router) {
  router.beforeEach(async (to, from, next) => {
    function gologin(needTips = false) {
      NProgress.done()
      if (to.name == 'notFound') {
        needTips && reLoginTips()
        return next('/login')
      } else {
        return next()
      }
    }


    NProgress.start()
    const userStore = useUserStore()
    if (to.query.iframe_mode) {
      useSystemStore().setIframeMode()
      to.query.token && userStore.setToken(to.query.token as string)
    }

    if (!useAppStore().reference) {
      await useAppStore().refreshReference()
    }

    if (getItem(STORAGE_KEY_TOKEN)) {
      if (!userStore.user) {
        let res = await authApi.checkToken()

        if (res.code === ProjectCode.TokenExpired) {
          // eslint-disable-next-line max-depth
          if (to.name === 'loginLoading') {
            NProgress.done()
            return next()
          } else {
            return gologin(true)
          }
        }

        await userStore.getUserInfo()
        NProgress.done()
        return next({
          path: to.path,
          query: to.query
        })
      }
      if (to.name == 'login') {
        NProgress.done()
        return next(to.query?.redirect as string || '/')
      }
    } else if (to.name != 'login' && to.name !== 'loginLoading') {
      NProgress.done()
      return next({ name: 'login' })
    }
    NProgress.done()
    next()
    // NProgress.done()
  })
}
