import { redirectRouter } from '@/hooks/router-redirect'
import EmptyLayout from '@/views/layouts/empty-layout.vue'
import { CustomRouteRecordRaw, RouteLocationRaw } from 'vue-router'
const clientChildrenRoutes = [
  {
    path: 'client-list',
    name: 'clientList',
    component: () => import('@/views/client/client-list.vue'),
    meta: {
      title: '组织列表',
      iconfont: 'icon-user-settings-line',
      requiredPermission: 'CLIENT_LIST',
    }
  },
  {
    path: 'menu-list',
    name: 'menuList',
    component: () => import('@/views/client/menu-list.vue'),
    meta: {
      title: '菜单列表',
      iconfont: 'icon-file-line',
      requiredPermission: 'MENU_LIST',
    }
  },
  {
    path: 'permission-list',
    name: 'permissionList',
    component: () => import('@/views/client/permission-list.vue'),
    meta: {
      title: '权限管理',
      iconfont: 'icon-folder-keyhole-line',
      requiredPermission: 'PERMISSION_LIST',
    }
  },
  // 字典配置
  {
    path: 'dictionary-config-list',
    name: 'dictionaryConfigList',
    component: () => import('@/views/client/dictionary-config-list.vue'),
    meta: {
      title: '字典配置',
      iconfont: 'icon-book-2-line',
      requiredPermission: 'DICTIONARY_CONFIG_LIST',
    }
  },
  {
    path: 'dictionary-config-child',
    name: 'DICTIONARY_CONFIG_CHILD',
    component: () => import('@/views/client/dictionary-config-child.vue'),
    meta: {
      hideInMenu: true,
      parentNameForBreadcrumb: 'dictionaryConfigList',
      // actionRouterName: 'dictionaryConfigList'
    }
  },
  {
    path: 'faq-list',
    name: 'faqList',
    component: () => import('@/views/client/faq-list.vue'),
    meta: {
      title: '帮助中心',
      iconfont: 'icon-help_outline_black_24dp',
      requiredPermission: 'FAQ_LIST',
    }
  },
  {
    path: 'deal-customer',
    name: 'dealCustomer',
    component: () => import('@/views/client/deal-customer.vue'),
    meta: {
      title: '成交客户',
      iconfont: 'icon-file-list-line',
      requiredPermission: 'DEAL_CUSTOMER',
    },
  },
  {
    path: 'contract-statistics',
    name: 'contractStatistics',
    component: () => import('@/views/client/contract-statistics.vue'),
    meta: {
      title: '电子合同统计',
      iconfont: 'icon-article-line1',
      requiredPermission: 'CONTRACT_STATISTICS',
    },
  },
  {
    path: 'contract-statistics-detail',
    name: 'CONTRACT_STATISTICS_DETAIL',
    component: () => import('@/views/client/contract-statistics-detail.vue'),
    meta: {
      title: '电子合同统计详细',
      hideInMenu: true,
      iconfont: 'icon-article-line1',
      requiredPermission: 'CONTRACT_STATISTICS_DETAIL',
    },
  },

  {
    path: 'pending-review-project-list',
    name: 'pendingReviewProjectList',
    component: () => import('@/views/client/pending-review-project-list.vue'),
    meta: {
      title: '待审核项目列表',
      iconfont: 'icon-database-2-line',
      requiredPermission: 'PENDING_REVIEW_PROJECT_LIST',
    },
  },
  {
    path: 'tenant-list',
    name: 'tenantList',
    component: () => import('@/views/client/tenant-list.vue'),
    meta: {
      title: '主账号多组织切换',
      iconfont: 'icon-user-settings-line',
      requiredPermission: 'TENANT_LIST',
    },
  },
  {
    path: 'user-list',
    name: 'userList',
    component: () => import('@/views/client/user-list.vue'),
    meta: {
      title: '用户列表',
      iconfont: 'icon-database-2-line',
      requiredPermission: 'USER_LIST',
    },
  },
  {
    path: 'client-insurance-plan',
    name: 'CLIENT_INSURANCE_PLAN',
    component: () => import('@/views/client/client-insurance-plan.vue'),
    meta: {
      title: '社保方案',
      iconfont: 'icon-user-settings-line',
      requiredPermission: 'CLIENT_INSURANCE_PLAN',
    },
  },
  {
    path: 'accumulation-fund',
    name: 'ACCUMULATION_FUND_PLAN',
    component: () => import('@/views/client/accumulation-fund.vue'),
    meta: {
      title: '公积金方案',
      iconfont: 'icon-pages-line',
      requiredPermission: 'ACCUMULATION_FUND_PLAN',
    },
  },

]
export const clientRoutes: CustomRouteRecordRaw = {
  path: 'client',
  name: 'client',
  component: EmptyLayout,
  redirect: (): RouteLocationRaw => redirectRouter(clientChildrenRoutes),
  meta: {
    title: '组织管理',
    iconfont: 'icon-community-line',
    desc: '以组织为维度内容',
    requiredPermission: 'CLIENT',
    noQuick: true

  },
  children: clientChildrenRoutes
}
