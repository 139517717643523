<template>
  <div
    class="overflow-hidden grid-table-container"
    :class="{'bg-white':systemStore.layout.theme === 'light' }"
  >
    <div
      v-if="showTableTitle && (title || tableTitle)"
      class="table-title "
    >
      {{ tableTitle||title }}
    </div>
    <!-- tab栏插槽,招聘需求详情使用，不是通用tab栏切换 -->
    <div class="px-5">
      <slot name="tabBar" />
    </div>
    <!-- <div class="-pb-6"> 使table底部贴紧浏览器底部 -->
    <div
      v-if="propsRef.searchSchemas?.length"
      class="px-2 py-2 bg-white grid-table-search-bar"
      style="border-bottom: solid 1px #EFEFEF;"
    >
      <basic-form
        v-if="tableWidth"
        ref="baseFormRef"
        :is-search-form="true"
        :table-width="tableWidth"
        :schemas="propsRef.searchSchemas"
        :collapsible="true"
        :reset-do-submit="true"
        submit-text="搜索"
        @submit="search"
        @toggle-collapse="autoTableHeight"
      />
    </div>

    <div
      ref="tableWrapElRef"
      class="px-2 pt-2"
    >
      <div
        v-if="propsRef.showTableHeader"
        class="mb-1"
      >
        <table-header
          @create="handleCreate"
          @setFullscreen="setFullscreen"
        />
      </div>
      <table-body
        v-bind="bindAttrs"
        ref="tableBodyRef"
      >
        <template
          v-for="slot in Object.keys(slots)"
          #[slot]="data"
        >
          <slot
            :name="slot"
            v-bind="data || {}"
          />
        </template>

        <template
          v-if="isSummary && dataSourceRef.length > 0"
          #summary
        >
          <component :is="getSummary(dataSourceRef)" />
          <slot name="customSummary" />
        </template>
      </table-body>
    </div>
  </div>
</template>

<script setup lang="ts">
import { createTableContext } from './hooks/context'
import { useDataSource } from './hooks/datasource'
import { useLoading } from './hooks/loading'
import { gridTableProps } from './props'
import { usePagination } from './hooks/pagination'
import { useColumn } from './hooks/column'
import { useSelection } from './hooks/selection'
import { useScroll } from './hooks/scroll'
import { omit } from 'lodash-es'
import BasicForm from '../form/basic-form.vue'
import TableBody from './components/table-body.vue'
import { useAction } from './hooks/action'
import { useSystemStore } from '../../store/modules/system'

const systemStore = useSystemStore()
const tableWrapElRef = ref()
const tableBodyRef = ref<IGridTable.TableBodyExpose>()
const tableWidth = computed(() => (tableWrapElRef.value as HTMLElement)?.offsetWidth ?? 0)
const baseFormRef = ref()
const attrs = useAttrs()
const slots = useSlots()
const props = defineProps(gridTableProps)
const dynamicProps = ref<Partial<IGridTable.Props>>()
const propsRef = computed(() => ({
  ...attrs,
  ...props,
  ...unref(dynamicProps),
} as IGridTable.Props))


const { setLoading, getLoading } = useLoading()
const { paginationRef, setPagination } = usePagination(propsRef)
const { selectedKeysRef, selectedRowsRef, resetSelection, selectionRef, setSelectedKeys, setSelectedRows } = useSelection(propsRef)
const { reload, search, rawDataSourceRef, dataSourceRef, totalRef } = useDataSource(propsRef, paginationRef, {
  setLoading,
  setPagination,
})

/**
 * 解决table双滚动 https://github.com/ant-design/ant-design/issues/33762
 * 加了sticky属性再滚动的时候，这时候并没有触发浏览器的的resize事件，导致横向滚动条不受控的出现两条和点击消失
**/
watchEffect(() => {
  if (unref(dataSourceRef)?.length > 0) {
    document.documentElement.scrollTop += 1
    document.documentElement.scrollTop -= 1
  }
})
// watch(dataSourceRef, () => {
//   autoColumnWidth()
// })

const autoTableHeight = () => {
  nextTick(() => {
    autoHeight()
  })
}
const { handleCreate, handleEdit, handleDel } = useAction(propsRef, reload)
const { columnsRef, scrollRef, setColumns, getColumns, getSummary, isSummary, autoColumnWidth, autoHeight } = useColumn(
  tableBodyRef,
  propsRef,
  paginationRef,
  rawDataSourceRef,
  {
    handleEdit,
    handleDel,
  }
)
const { setFullscreen, setScollY } = useScroll(propsRef, tableBodyRef, columnsRef, scrollRef)
const bindAttrs = computed(() => ({
  rowKey: 'id',
  ...omit(unref(propsRef), 'title'),
  loading: unref(getLoading),
  columns: unref(columnsRef),
  rowSelection: unref(selectionRef),
  pagination: unref(paginationRef),
  dataSource: unref(dataSourceRef),
  scroll: unref(scrollRef),
  onChange: pagination => {
    setPagination(pagination)
    reload()
  },
}), {
  onTrigger(e) {
    // 当 count.value 被修改时触发
    // console.log(e)
  }
})

function setProps(newProps: Partial<IGridTable.Props>) {
  dynamicProps.value = {
    ...unref(dynamicProps),
    ...newProps,
  }
}
watch(getLoading, v => {
  if (!v && unref(dataSourceRef)?.length > 0) {
    autoColumnWidth()
  }
})

onActivated(() => {
  if (!unref(getLoading) && unref(dataSourceRef)?.length > 0) {
    autoColumnWidth()
  }
})

watch(() => props.getListApiParams, v => {
  nextTick(() => {
    v && baseFormRef.value?.setFormModel(v)
  })
}, { immediate: true })


const exposeData: IGridTable.Expose = {
  autoColumnWidth,
  wrapLlRef: tableWrapElRef,
  reload,
  setProps,
  getProps: () => propsRef,
  setLoading,
  getColumns,
  setColumns,
  setPagination,
  paginationRef,
  getSearchParams: () => unref(baseFormRef.value?.formModel),
  setSearchParams: v => {
    baseFormRef.value?.setFormModel(v)
    search(v)
  },
  getDataSource: () => unref(dataSourceRef),
  getTotal: () => unref(totalRef),
  resetSelection,
  getSelectionKeys: () => unref(selectedKeysRef),
  getSelectionRows: () => unref(selectedRowsRef),
  setSelectedKeys,
  setSelectedRows,

  tableBodyRef: computed(() => unref(tableBodyRef.value?.tableBodyRef)),
  editingData: computed(() => tableBodyRef.value?.editingData),
  saveAllEditData: () => tableBodyRef.value?.saveAllEditData(),
  editAllEditData: () => tableBodyRef.value?.editAllEditData(),
  cancelAllEditData: () => tableBodyRef.value?.cancelAllEditData(),
}
defineExpose(exposeData)
createTableContext(exposeData)
</script>
