<style lang="less" scoped>
.box {
  display: flex;
  justify-content: space-between;

  .ant-input-group {
    width: 100%;
    text-align: left;
  }
}
</style>

<template>
  <div class="w-full box">
    <a-input-group
      compact
      class="w-full"
    >
      <a-input
        v-bind="bindAttrs"
        ref="inputEl"
        :style="{width: `calc(100% - ${buttonWidth}px)`}"
        autocomplete="off"
        @update:value="handleChange"
      />
      <a-button
        type="primary"
        :style="{width: `${buttonWidth}px`}"
        :disabled="!inputValue"
        :loading="loading"
        @click="handleClick"
      >
        {{ buttonText }}
      </a-button>
    </a-input-group>
  </div>
</template>
<script lang="ts" setup>
import { inputButtonProps } from './props'

// useFormContext 内部使用了 inject，而 inject 仅能在 setup 顶部调用，故不可在 函数中再调用
// https://v3.cn.vuejs.org/api/composition-api.html#provide-inject
const attrs = useAttrs()
const props = defineProps(inputButtonProps)
const inputEl = ref()
const bindAttrs = computed(() => ({
  ...attrs,
  ...props,
}))
const loading = ref(false)
const inputValue = ref('')
type EmitEvents = {
  (e:'update:value', value):void
}
const emits = defineEmits<EmitEvents>()
async function handleClick() {
  loading.value = true
  await props.callback(unref(inputValue))
  loading.value = false
}
function handleChange(value) {
  inputValue.value = value
  emits('update:value', value)
}

</script>
