import { PropType } from 'vue'
import { DraftType } from '../../hooks/draft-box'

export const gridTableProps = {

  /** 标题 关联新建 修改标题 */
  title: String,

  /** 表格标题 未设置时默认使用title  */
  tableTitle: String,

  size: {
    type: String,
    default: 'small',
  },

  /** 是否显示表格左上角标题 */
  showTableTitle: {
    type: Boolean,
    default: false
  },

  /** 表格移动端卡片形式 */
  cardable: {
    type: Boolean,
    default: true,
  },

  /** 增、删、改、查 */
  api: [Function, Object] as PropType<IGridTable.Api>,

  /** 列表请求额外参数 */
  getListApiParams: Object,

  /** 列表搜索配置项 */
  searchSchemas: Array as PropType<IForm.Schema[]>,

  /** 增、改 配置项 */
  formSchemas: Array as PropType<
    (IForm.Schema & {

      /** 仅在编辑时显示 */
      onlyEdit?: boolean;

      /** 仅在创建时显示 */
      onlyCreate?: boolean;
    })[]
  >,

  baseItemColSpan: {
    type: Number,
    default: 12,
  },

  /** 列渲染配置项 */
  columnSchemas: {
    type: Array as PropType<IGridTable.ColumnProps[]>,
    required: true,
  },

  /** 表格操作 */
  tableActions: Array as PropType<IGridTable.Action[]>,

  /** 表格操作按钮是下拉时的文本 */
  // tableNeedSelectionLabel: {
  //   type: String,
  //   // 默认值没有用
  //   defalut: '批量操作'
  // },

  /** 表格操作按钮是下拉时是否需要选择表格数据才能显示按钮，默认true，兼容之前的配置 */
  tableNeedSelectionNeedChoose: {
    type: Boolean,
    defalut: true
  },

  autoWidth: {
    type: Boolean
  },

  autoHeight: {
    type: Boolean,
    default: true
  },


  /** 表格行操作 */
  rowActions: Array as PropType<IGridTable.RowAction[]>,

  /** 表格行操作宽度 */
  rowActionsWidth: Number as PropType<number>,

  /** 表格行默认分页数量 */
  tableDefaultPageSize: Number as PropType<number>,

  immediate: {
    type: Boolean,
    default: true,
  },

  /** 表格配置key */
  configKey: String,

  /** 数据是否可拖拽 */
  draggable: Boolean,

  /** 是否展示行序号 */
  indexable: Boolean,

  /** 是否可以选择行 */
  selectable: Boolean,

  /** 是否可导出 */
  exportable: Boolean,

  actionable: Boolean as PropType<boolean>, //
  /** 导出数据表格处理函数 */
  exportHandler: Function as PropType<(params: {
    tableHeader: string[],
    tableData: (string | number)[][],
    apiData: any[],
    filename: string,
  }) => Promise<void>>,

  /** 是否启用草稿功能 */
  draftable: String as PropType<DraftType>,

  /** 是否可以创建新数据 */
  creatable: {
    type: [Boolean, Function] as PropType<boolean | Fn>,
    default: () => false,
  },

  /** 创建新数据成功后的回调 */
  createdCallback: Function,

  /** 创建文字 */
  createText: String,

  /** 是否可以更新数据 */
  editable: {
    type: [Boolean, Function] as PropType<boolean | Fn>,
    default: () => false,
  },

  /** 数据编辑成功后的回调 */
  editedCallback: Function,

  /** 是否可以删除数据 */
  deleteable: {
    type: [Boolean, Function] as PropType<boolean | Fn>,
    default: () => false,
  },

  /** 数据删除成功后的回调 */
  deletedCallback: Function,
  // 弹窗宽度
  modalWidth: Number as PropType<number>, //

  /** 是否表单表格 */
  isFormTable: {
    type: Boolean,
    default: false,
  },

  onEditDataSave: {
    type: Function as PropType<Fn<Recordable>>,
    default: () => false,
  },

  /** 显示表格头部 */
  showTableHeader: {
    type: Boolean,
    default: true,
  },

  /** 显示表格工具栏 */
  showTools: {
    type: Boolean,
    default: true,
  },

  /** 表格多选禁止状态 */
  getCheckboxProps: {
    type: Function,
  },

  /** 弹出窗关闭时是否删除子元素 */
  modalDestroyOnClose: {
    type: Boolean,
    default: false
  },

  // 当新增和编辑使用配置creatable和editable时，使用的是同一个弹框，同一个表单schemas，但是不知道当前点击的是编辑还是新增按钮
  clickEditOrNewFn: {
    type: Function as PropType<(type: 'edit' | 'new') => void>,
    default: () => { }
  },
  // 处理编辑时的数据
  handleEditRecordDataFn: {
    type: Function as PropType<(record: Recordable) => any>,
    default: undefined
  },
}
