import { ExtractPropTypes } from 'vue'

export const inputButtonProps = {
  buttonText: {
    type: String,
    default: '提交'
  },
  buttonWidth: {
    type: Number,
    default: 110,
  },
  callback: {
    type: Function,
    default: () => { }
  }
}

export type InputButtonProps = Partial<ExtractPropTypes<typeof inputButtonProps>>