import { ExtractPropTypes } from 'vue'
import type { SelectProps } from 'ant-design-vue/es/select'
// type SelectorTypes = keyof Reference.SelectorTypes

export const invoiceContentSelectProps = {
  apiParams: {
    type: Object,
    default: () => ({}),
  },
  valueField: {
    type: String,
    default: 'id'
  },
  labelField: {
    type: String,
    default: 'text'
  },
}

export type InvoiceContentSelectProps = Partial<ExtractPropTypes<typeof invoiceContentSelectProps>> & SelectProps